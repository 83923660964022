
/*General*/
html {
    background-color: #000066
}

#root {
    background-color: #000066
}


/*Transaction*/
#validtype {
    display: flex;
    justify-content: center;
}

#transac_list {
    background-color: #F0F0F0
}


/*Auth page*/

#logbutton {
    color:rgb(255, 255, 255);
    background-color:#4688F4;
    text-align:center;
    text-decoration: none;
    height:37px;
    display: flex;
    float:left;
    align-items: center;
    padding-right:10px;
    padding-left:10px;
}

#btnbox{
    min-height: 25%;  /* Fallback for browsers do NOT support vh unit */
    min-height: 25vh; /* These two lines are counted as one :-)       */
    display: flex;
    background:#F0F0F0;
    justify-content: center;
}

#logoGoogle{
    background-color:rgb(255, 255, 255);
    display:flex;
    float:left;
}

#intro-icon{
    align-items: center;
    font-size:2em;
    font-weight:bold;
}

#intro-text{
    display:inline;
    font-size:1em;
    font-family:'Rubik', sans-serif;
    margin-left:5px;
}

#intro-desc{
    font-size:1em;
    font-family:'Rubik', sans-serif;
    margin-top:10px;
}

/*Navbar*/

.navbar {
    background-color:#000000
}

.navbar-nav .nav-link {
    margin-right: 10px;
    margin-left: 10px;
}


/*Dashboard*/
#chartCard{
    background-color:rgb(255, 255, 255, 0.03);
}

/*Les deux classes suivantes permettent de centrer le text des labels du treemap*/
.custom-tooltip-treemap {
    background-color:rgb(231, 231, 231);
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.label-treemap {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}